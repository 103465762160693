.evento{
	color: white;
	position: relative;
	animation-name: evento;
	animation-duration: 15s;
}
@keyframes evento {
	0%   {
		color:red;
		 left:0px; 
		 top:0px;
		}
	100%  {
		color:yellow; 
		left:200px; 
		top:0px;
	}
}
.img{
	width: 90%;
	margin-bottom: 15px;
}


@media screen and (max-width: 660px) {
	.bntWidth{
		width: 100%;
	}
}